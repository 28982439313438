header {
    background-color: #fff;
}

.card {
    border: 0;
    border-radius: 0;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        padding: 0.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 1.5rem;
    }

    .card-header {
        border-radius: 0;
        background-color: #fff;
        border-bottom: 3px solid $gray-700;
    }
}

.content-type-label {
    font-family: $font-family-sans-serif-bold;
    font-weight: 500;
    font-size: 0.875rem;
    color: $info;
    text-transform: uppercase;
}

.content-type {
    font-size: 3rem;
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.time-indicator {
    text-align: left;

    @include media-breakpoint-up(sm) {
        text-align: right;
    }
}

.time-container {
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
        flex-direction: column;
    }

    .icon-gecko-time {
        font-size: 2.5rem;
        display: block;
        margin-right: 0.5rem;

        @include media-breakpoint-up(sm) {
            margin-right: 0;
        }
    }
}

.course-instruction {
    font-weight: bold;
    color: #692889;
}

audio {
    width: 100%;
}

.progress,
.alert {
    border-radius: 0;
}

.badge {
    font-weight: 500;
}

.btn-vote {
    width: 56px;
    height: 56px;
}

.btn-like {
    background: none;
}

//Settings toggle switch

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

// @media screen and (max-width: 500px) {
//     .switch {
//         width: 80px !important;
//     }
// }

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-500;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: $success;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


.discussion-col-search {
    min-width: 270px;
}

.discussion-col-sort {
    min-width: 180px;
}

.list-group {
    &.replies {
        .list-group-item {
            &:nth-last-child(2) {
                border-bottom: 0;
            }
        }
    }
}


.course-card {
    &:hover {
        box-shadow: 1px 1px 7px 2px #ccc;
    }
}

.hide-show-class {
    display: none;
}

//passed in from the API layer
.reveal-class {
    list-style: none;
    padding: 8px;
    padding-left: 20px;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    box-shadow: 0 0 3px 1px #e7e7e7;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
}

.element_toggler {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.text-muted {
    font-size: 0.8rem;
}


.offline-text {
    color: $info;
    background-color: #fff;
    padding: 0;
    border-radius: 6px;
    margin: 0;
    font-size: 0.775rem;
    padding: 0;
    margin: 0;
    margin-left: 8px;
}

.my-flex-container {
    width: 100%;
}

// fudge for section header being an activity group
.section-header {
    border-radius: 6px;

    .card-header {
        background-color: transparent !important;
        border-bottom: none !important;
    }

    .content-type-label {
        display: none;
    }
}

.space-adjustment {
    padding-left: 30px !important;
}

.btn-light {
    box-shadow: 1px 1px 3px 1px #e7e7e7;
}

@media (max-width: 330px) {

    // modile header for small mobiles
    .special-case-padding {
        padding: 0.25rem !important;
    }
}


@media (min-width: 768px) {
    .ebs-media-response {
        height: 450px;
        width: 100%
    }
}

.link {
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        cursor: pointer;
    }
}

.btn-min-width {
    min-width: 80px;
}


.text-activity-wrapper {
    .card-body {
        p:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.course-section-widget {
    padding: 20px;
    margin-bottom: 15px;
}

.btn-blue {
    color: #00a9e0;
    background-color: #fff;
    border-color: transparent;

    &:hover {
        color: #00a9e0;
        background-color: #fff;
        border-color: transparent;
    }
}

.featured {
    background-color: $info;
    color: #ffffff;

    a,
    a:visited {
        color: #ffffff;
        text-decoration: underline;

        &:hover {
            color: darken($color: #ffffff, $amount: 10);
        }

        &.btn-light {
            color: darken($color: $info, $amount: 30);
            text-decoration: none;
            box-shadow: none;
            &:hover {
                color: darken($color: $info, $amount: 50);
            }
    
        }

    }
}
.stats-text {
    min-height: 55px;
}

.course-header-bar {
    background-color: #f8f9fa !important;
}

.img-placeholder {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 0;
    margin-bottom: 15px;
}

ebs-essay-question{
    textarea{
        box-shadow: none !important; 
    }
}

@include media-breakpoint-down(xs) {

    //mobile tables scrolling
    ::-webkit-scrollbar {
        width: 10px !important;
    }

    ::-webkit-scrollbar {
        height: 12px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        -webkit-border-radius: 7px !important;
        border-radius: 7px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 7px !important;
        border-radius: 7px !important;
        background-color: #41617D !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;

    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: #41617D !important;
    }

    ::-webkit-overflow-scrolling {
        -webkit-overflow-scrolling: auto
    }

    .ng-scroll-view[_ngcontent-c3] {
        overflow-x: hidden;
    }

    .table-responsive {
        margin-bottom: 15px;
    }


    .intro {
        font-size: 0.9rem;
        padding-top: 5px !important;

        p {
            margin-bottom: 0.75rem;
        }
    }

    .login-form {
        padding-top: 0 !important;
        font-size: 0.9rem;
    }

    .course-section-widget {
        padding: 10px;
        margin-bottom: 10px;
    }
}

@include media-breakpoint-up(md) {
    .course-card{
        .card-body{
            min-height: 105px !important;
        }
    }
}

//IE10/11 only
@media screen and(-ms-high-contrast: active),
(-ms-high-contrast: none) {

    /**
     * IE11 Fix image auto height
     * https://github.com/philipwalton/flexbugs/issues/75
     */
    ebs-activity-group {
        .card {
            display: block;
        }
    }

    #section-content-wrapper {
        width: 100%;
    }

    p {
        flex-shrink: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .modal-dialog .modal-body {
        padding: 1.25rem !important;
    }
}