// Headings
//

h1, .h1, h2, .h2, .lead {
    font-family: $font-family-serif;
   
}
h1{
    font-size:  $h1-font-size-mobile; 
    @include media-breakpoint-up(md) {font-size:  $h1-font-size;} 
}

h2{
    font-size:  $h2-font-size-mobile; 
    @include media-breakpoint-up(md) {font-size:  $h2-font-size;} 
}
h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: $font-family-sans-serif-bold;
}

p {
    margin-bottom: 1.5rem;
}

strong {
    font-family: $font-family-sans-serif-bold;
    font-weight: 500;
}

.course-title{
    overflow: hidden;
    @include media-breakpoint-down(sm) { font-size: 0.75rem; padding: 15px;} 
    @include media-breakpoint-up(md) { margin-left:5px;}
    @include media-breakpoint-up(lg) { margin-left:5px;}

}

// table buttons 
.table {
    font-size:0.75rem;
    @include media-breakpoint-up(md) { font-size:1rem;}
    td.action-btns{
        padding:.25rem;
        vertical-align: middle;
    }

}
@include media-breakpoint-down(sm) { 

    .alert-dismissible {
        padding: .75rem;
        padding-right: ($close-font-size + 1.5rem);
    
        // Adjust close link position
        .close {
            padding: $alert-padding-y 0.75rem;

        }
        .btn{
            margin-right: -($close-font-size + 0.75rem)
        }
    }

} 

.activity-indicator{
    position: absolute;
    left:0;
    top:0;
    width:10px;
    height: 10px;
    background-color: $danger;
    border-radius: 50%;
}

.progress-success-tick{
    font-size: 3rem; width: 80px; height: 80px;
}

.post-author{
    font-size: 0.75rem;
}

.lh-2{
    line-height: 2;
}
