/*ngx-bootstrap alert overrides */

.alert {
    box-shadow: none;
    margin: .5rem 0 .5rem 0;
    margin-bottom: 10px;
    padding: .25rem 11px;
    border-radius: 0.25rem;
}

/*toaster overrides */

.toast-container {
    .toast {
        width: 100% !important;
        box-shadow: 1px 1px 6px 0px !important;
    }
}

.toast-success {
//     background-image:url(svg-encode(
//  svg-factory(theme-color-level('success', 6), '0 0 512 512', 'M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'))) !important;
background-size: 34px !important;
    padding-left: 60px !important;
}

.toast-error {
 //   background-image:url(svg-encode(svg-factory(theme-color-level('danger', 6), '0 0 512 512', 'M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'))) !important;
    background-size: 34px !important;
    padding-left: 60px !important;
}

.toast-info {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  //  background-image:url(svg-encode(svg-factory(theme-color-level('info', 6), '0 0 512 512', 'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'))) !important;
    background-size: 34px !important;
    padding-left: 60px !important;
}

.toast-warning {
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
//     background-image:url(svg-encode(
//  svg-factory(theme-color-level('warning', 6), '0 0 576 512', 'M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'))) !important;
    background-size: 34px !important;
    padding-left: 60px !important;
}

.alert-warning {
    color: $warning;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-error {
    color: $warning;
    background-color: #f5ccd7;
    border-color: #f1b8c7;
}