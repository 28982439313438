.dashboard-card {
    opacity: 90%;
    margin-bottom: 32px;
    div {
        opacity: 100%;
    }

    h3 {
        font-family: $font-family-serif;
    }
}

// Card styling for course dashboard screen
.course-card {
    margin-bottom: 2rem !important;
    max-width: 100%;
    min-width: 100%;
    opacity: 90%;

    .card-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        a.link-area {
            display: flex;
            flex: 1;
            justify-content: space-between;
        }
        .title {
            padding-right: 1rem;
            flex: 2 1 89%;
            opacity: 100%;
        }
        .button {
            flex: 1 2 auto;
            max-width: 38px;
            @include media-breakpoint-down(sm) {
                .btn {font-size: 0.6rem; padding: 6px 8px;}
            }
        }
        .text {flex: 3 1 auto; align-self: flex-end;}
    }


    @include media-breakpoint-up(sm) {
        max-width: 510px;
        min-width: 510px;
    }

    @include media-breakpoint-up(md) {
        max-width: 345px;
        min-width: 345px;
        .card-body .title {
            max-width: 265.4px;
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 465px;
        min-width: 465px;
        .card-body .title {
            max-width: 353.4px;
        }
    }

    @include media-breakpoint-up(xl) {
        max-width: 360px;
        min-width: 360px;
        .card-body .title {
            max-width: 248.4px;
        }
  }

    @include media-breakpoint-up(xxl) {
        max-width: 493.33px;
        min-width: 493.33px;
        .card-body .title {
            max-width: 381.728px;
        }
    }

}

.card-deck-compressed {
    margin-bottom: 20px;
    .course-card {
        margin-bottom: .5rem !important;
        padding: 0;
        .card-body{
            min-height: 0 !important;
        }
        .button {
            max-width: 38px;
            .btn {font-size: 0.6rem; padding: 3px 4px 2px 4px;}
        }
        .card-body {
            .title {
                font-size: 1rem;
                margin-bottom: 0;
            }

            .link {
                color: #000;
            }

        }    
    }
}

.coursera-card {
    margin-bottom: 2rem !important;
}